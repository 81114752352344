<template lang='pug'>
head-meta(title-page="Подтверждение почты" )
the-verify-email
</template>

<script>
import HeadMeta from '../components/_layout/HeadMeta'
import TheVerifyEmail from '../components/verify-email/TheVerifyEmail'
export default {
  name: 'VerifyEmailPage',
  components: { TheVerifyEmail, HeadMeta }
}
</script>
