<template lang='pug'>
article.login
  .login__cont.cont
    template(v-if="status === 'progress'")
      h1.login__caption.caption Проверяем почту...
    template(v-else-if="status === 'error'")
      h1.login__caption.caption Ошибка подтверждения
      p.login__text.text-big Пользователь не найден, либо истек срок действия ссылки
    template(v-else-if="status === 'success'")
      h1.login__caption.caption Спасибо!
      p.login__text.text-big Почта подтверждена, теперь можно использовать аккаунт
      .login__link-box
        router-link.login__link-login.button-orange.text(:to= "{ name: 'Login' }") Войти
</template>

<script>
import '@/assets/styles/components/login.sass'
import { verifyEmail } from '../../assets/scripts/Api'

export default {
  name: 'TheVerifyEmail',
  data () {
    return {
      status: 'progress',
      token: null
    }
  },
  methods: {
    checkToken () {
      this.token = this.$route.query.token
      if (!this.token) {
        this.status = 'error'
      } else {
        verifyEmail(this.token).then(data => {
          this.status = data.status === 'error' ? 'error' : 'success'
        })
      }
    }
  },
  mounted () {
    this.checkToken()
  }
}
</script>
